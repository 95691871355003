import { Divider, Tooltip } from 'antd';
import CustomTableDashboard from 'common/Content/CustomTableDashboard';
import styles from './index.module.less';

export default function Table_open_allocated({ count_BU_wise }) {
  const count_BU_wise1 = count_BU_wise?.map((item) => ({
    key: item?.index,
    buName: item?.name,
    count: item?.totalCount,
    value: item?.totalValue
  }));
  const columns = [
    {
      title: <span>BU</span>,
      dataIndex: 'buName',
      key: 'buName',
      sorter: (a, b) => a.buName.localeCompare(b.buName),
      ellipsis: true,
      // width: '200px',
      render: (buName) => {
        return (
          <>
            <Tooltip
              title={buName}
              color={'white'}
              overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
              arrowPointAtCenter="false"
              placement="top"
            >
              <span style={{ textTransform: 'uppercase' }}>{buName}</span>
            </Tooltip>
          </>
        );
      }
    },
    /* {
      title: 'Open', //style={{backgroundColor:"white !important", color:"black"}}
      dataIndex: 'total_open_invoices',
      key: 'total_open_invoices',
      sorter: (a, b) =>
        Number(a.total_open_invoices) - Number(b.total_open_invoices),
      ellipsis: {
        showTitle: false
      },
      //  width: '200px',
      render: (total_open_invoices) => {
        return (
          <>
            <Tooltip
              title={total_open_invoices}
              color={'white'}
              overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
              arrowPointAtCenter="false"
              placement="top"
            >
              {total_open_invoices}
            </Tooltip>
          </>
        );
      }
    }, */
    {
      title: 'Count of Gifts', //style={{backgroundColor:"white !important", color:"black"}}
      dataIndex: 'count',
      key: 'count',
      sorter: (a, b) => Number(a.count) - Number(b.count),
      ellipsis: {
        showTitle: false
      },
      //  width: '200px',
      render: (count) => {
        // console.log('count################', count);
        return (
          <>
            <Tooltip
              title={count}
              color={'white'}
              overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
              arrowPointAtCenter="false"
              placement="top"
            >
              {count}
            </Tooltip>
          </>
        );
      }
    },
    {
      title: 'Value of Gifts', //style={{backgroundColor:"white !important", color:"black"}}
      dataIndex: 'value',
      key: 'value',
      sorter: (a, b) => Number(a.value) - Number(b.value),
      ellipsis: {
        showTitle: false
      },
      //  width: '200px',
      render: (value) => {
        return (
          <>
            <Tooltip
              title={value}
              color={'white'}
              overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
              arrowPointAtCenter="false"
              placement="top"
            >
              ₹{value}
            </Tooltip>
          </>
        );
      }
    }
  ];

  return (
    <div>
      <div className={`${styles.table_title}`}>
        <span
          style={{
            fontSize: '16px',
            fontWeight: 700,
            color: 'rgba(0,0,0,0.85)'
          }}
        >
          {' '}
          BU-wise Gifts
        </span>
      </div>
      <Divider style={{ margin: '0px' }} />
      <CustomTableDashboard
        dataSource={count_BU_wise1}
        columns={columns}
        pagination={{
          size: 'small'
        }}
      />
    </div>
  );
}
