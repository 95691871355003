import React from 'react';
import { Chart } from 'react-google-charts';
import { sizeChecker } from 'utilities/helpers';

function PaiChart({ govtAndNonGovt }) {
  const isMobile = sizeChecker('xs');

  const options = {
    title: 'Gifts given to Government & Non-government  officials',
    colors: ['#3366cc', '#109618'],
    is3D: true,
    fontSize: 16
  };

  let newArray = ['', ''];

  const arrOfArr4 = Object.keys(govtAndNonGovt).map((key) => [
    // key['name'] === 'government' ? 'Govt' : 'Non-govt',
    govtAndNonGovt[key]['name'] === 'government' ? 'Govt' : 'Non-govt',
    govtAndNonGovt[key]['totalCount']
  ]);

  let newArrOfArr = [newArray, ...arrOfArr4];

  // console.log('arrOfArr4', arrOfArr4);
  // console.log('newArrOfArr', newArrOfArr);

  return (
    <Chart
      chartType="PieChart"
      data={newArrOfArr}
      options={options}
      width={isMobile ? '300' : '700px'}
      height={isMobile ? '300' : '700px'}
    />
  );
}
export default PaiChart;
